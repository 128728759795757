<template>
    <div class="lg:px-24 md:px-8 px-3">
        <div class="lg:w-1/2 w-full">
            <p class="font-blackMango tracking-wide text-baselg text-center lg:text-left flex justify-center lg:justify-start items-center mb-4 lg:mb-0" v-if="location">
              <span class="hidden lg:block text-bronze text-xl mr-2 location-bullet">&#8226;</span>
              <span>{{ location }}</span>
              <span class="hidden lg:block font-euclid text-sm listing-ref" v-if="getReference">ref. {{getReference}}</span>
            </p>
            <p class="font-blackMango text-center md:w-2/3 lg:w-full inset-x-0 mx-auto lg:text-left tracking-wider font-bold text-lg mt-1">{{title}}</p>
        </div>
        <div class="flex w-full mt-1 lg:mt-3 gap-16">
          <div class="w-full lg:w-1/2 lg:flex items-center listing-details-section gap-16 section-delimiter-bottom">
          <p class="lg:hidden font-euclid text-sm listing-ref text-center mb-1" v-if="getReference">ref. {{getReference}}</p>
            <p class="lg:hidden font-medium font-euclid w-full text-center text-md mb-1">{{ $t('TopContents.price_since') }}</p>
            <p  class="text-center lg:text-start text-lg text-bronze font-bold listing-price">
              <span v-if="priceVisibility == 0">
                {{$currency(price)}}
              </span>
              <span v-else>
                {{$t('price_under_request')}}
              </span>
            </p>
            <div class="hidden lg:flex justify-start pb-2 listing-details">
              <PropertyDetailsBig :number='rooms' img="/svg/houseDetails/rooms.svg" color="gray" />
              <PropertyDetailsBig :number='baths' img="/svg/houseDetails/baths.svg" color="gray" />
              <PropertyDetailsBig :number='garage' img="/svg/houseDetails/garage.svg" color="gray" />
              <PropertyDetailsBig :number='area' img="/svg/houseDetails/area.svg" color="gray" :measure="$t('propertyCard.area')" />
            </div>
          </div>
          <div class="hidden lg:flex w-full lg:w-1/2 flex gap-x-8">
            <p class="w-1/2 tracking-wide text-md text-center lg:text-left flex items-center font-bold font-euclid uppercase section-delimiter-bottom">
              {{$t('TopContents.details')}}
            </p>
            <p class="w-1/2 tracking-wide text-md text-center lg:text-left flex items-center font-bold font-euclid uppercase section-delimiter-bottom">
              {{$t('TopContents.characteristics')}}
            </p>
          </div>
        </div>
    </div>
</template>
<style scoped>
.listing-ref{
  margin-left: 10px;
  color: var(--color-gray);
  font-weight: 600;
}
@media (max-width:1150px){
  .listing-ref{
    margin-left: 0;
  }
}
.listing-price{
  line-height: initial;
  font-weight: 600;
}
.listing-details{
  gap:1rem;
  color:var(--color-darkgray);
}
.location-bullet{
  transform: translateY(-2px);
}
.section-delimiter-bottom{
  padding-bottom: 1rem;
  border-bottom: 2px solid black;
}
</style>
<script>
import PropertyDetailsBig from "@/components/listings/cards/PropertyDetailsBig";

    export default {
        name: "TopContent",
        components:{PropertyDetailsBig},
        props: {
          price: String,
          title: String,
          baths: null,
          rooms: null,
          garage: null,
          area: null,
          location: String,
          reference: {
            type: String,
            default: ''
          },
          priceVisibility: null
        },
        computed:{
          getReference(){
            return this.reference
          }
        }
    }
</script>