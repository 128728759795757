<template>
    <div class="relative lg:flex items-start mt-3 relative lg:px-24 md:px-8 px-3 gap-16" v-if="dataLoaded">
        <div class="lg:w-1/2 w-full">
            <!-- begin:: description-->
            <p class="text-justify leading-loose">{{description}}</p>
            <!-- begin:: book visit button-->
            <button @click="show()"
                class="mt-6 py-1 text-sm font-medium uppercase leading-none tracking-button inline-flex text-white items-center justify-center bg-black w-full">
                <span class="mr-2">{{ $t('buttons.bookVisit.book') }}</span>
                <div class="mt-2">
                    <inline-svg class="mb-1" fill="white" height="15" src="/svg/lock.svg" />
                </div>
                <span class="ml-2">{{ $t('buttons.bookVisit.visit') }}</span>
            </button>
            <!-- end:: book visit button-->
            <!-- end:: description-->
        </div>
        <!-- begin:: listing main info-->
        <div class="w-full lg:w-1/2  mt-8 lg:mt-0 lg:-top-0">
          <div class="lg:flex w-full">
              <p class="lg:hidden w-1/2 tracking-wide text-md text-center lg:text-left flex items-center font-bold font-euclid uppercase section-delimiter-bottom mb-4">
              {{$t('TopContents.details')}}
            </p>
            
            <div class="lg:flex lg:flex-col lg:gap-y-4 lg:mr-8 w-full">
                  <div class="flex justify-between" v-if="information.bedrooms != ''">
                      <p class="tracking-wide">{{ $t('properties.bedrooms') }}:</p>
                      <p class="text-left">{{ information.bedrooms }}</p>
                  </div>
                  <div class="flex justify-between" v-if="information.bathrooms != ''">
                      <p class="tracking-wide">{{ $t('properties.bath') }}:</p>
                      <p class="text-left">{{ information.bathrooms }}</p>
                  </div>
                  <div class="flex justify-between" v-if="information.parking != ''">
                      <p class="tracking-wide">{{ $t('properties.parking') }}:</p>
                      <p class="text-left">{{ information.parking }}</p>
                  </div>
                  <div class="flex justify-between" v-if="information.gross_area != ''">
                      <p class="tracking-wide">{{ $t('properties.gross_area') }}:</p>
                      <p class="text-left">{{ information.gross_area }} {{ $t('propertyCard.area') }}</p>
                  </div>
                  <div class="flex justify-between" v-if="information.living_area != ''">
                      <p class="tracking-wide">{{ $t('properties.living_area') }}:</p>
                      <p class="text-left">{{ information.living_area }} {{ $t('propertyCard.area') }}</p>   
                  </div>
                  <div class="flex justify-between" v-if="information.exterior_area != ''">
                      <p class="tracking-wide">{{ $t('properties.util_area') }}:</p>
                      <p class="text-left">{{ information.exterior_area }} {{ $t('propertyCard.area') }}</p>   
                  </div>
                  <div class="flex justify-between" v-if="computeInformation.types.floor != '' && computeInformation.types.floor != 0">
                      <p class="tracking-wide">{{ $t('properties.floor') }}:</p>
                      <p class="text-left">{{ computeInformation.types.floor }}</p>
                  </div>
                  <div class="flex justify-between" v-if="computeInformation.types.construction != ''">
                      <p class="tracking-wide">{{ $t('properties.construction_area') }}:</p>
                      <p class="text-left" v-if="computeInformation.types != null">{{ information.types.construction }} {{ returnType(information.types.construction_area_type) }}</p>   
                  </div>
                  <div class="flex justify-between" v-if="information.types.land_size != ''">
                      <p class="tracking-wide">{{ $t('properties.land_size_area') }}:</p>
                      <p class="text-left" v-if="computeInformation.types != null">{{ information.types.land_size }} {{ returnType(information.types.land_size_type) }}</p>   
                  </div>
                  <div class="flex justify-between" v-if="information.types.lote_size != ''">
                      <p class="tracking-wide">{{ $t('properties.lote_size_area') }}:</p>
                      <p class="text-left" v-if="computeInformation.types != null">{{ information.types.lote_size }} {{ returnType(information.types.lote_size_type) }}</p>   
                  </div>
                  <div class="flex justify-between" v-if="information.types.energy != ''">         
                      <p class="tracking-wide">{{ $t('properties.energy') }}:</p>
                      <p class="text-left" v-if="computeFeatures != null">{{ processEnergyType(information.types.energy) }}</p>
                  </div>
                  <div class="flex justify-between" v-if="computeFeatures.elevator != ''">    
                      <p class="tracking-wide">{{ $t('properties.elevator') }}:</p>
                      <p class="text-left" v-if="computeFeatures != null">{{ computeFeatures.elevator }}</p>
                  </div>
              </div>
                <p class="lg:hidden w-1/2 tracking-wide text-md text-center lg:text-left flex items-center font-bold font-euclid uppercase section-delimiter-bottom mb-4 mt-6">
              {{$t('TopContents.characteristics')}}
            </p>
              <div class="lg:ml-8 w-full">
                  <div class="flex flex-col gap-x-8 gap-y-2 mt-2">
                      <div class="flex items-center space-x-2" v-for="(feat, index) in featuresList" :key="index">
                          <inline-svg height="0.7rem" width="0.7rem" src="/svg/check.svg" class="feat-check"/>
                          <p>{{ $processLanguage(feat) }}</p>
                      </div>
                  </div>
              </div>
        
          </div>
          <div class="mt-0 lg:mt-10 w-full">
            <WhatsappContact color="bronze" :phoneNumber="phoneNumber" />
          </div>
        </div>
    </div>
</template>
<style scoped>
.feat-check{
  fill:var(--color-bronze)
}
</style>
<script>
    import { buildPayloadPagination } from '@/utils/utils'
    import mixin from '@/models/keys/KeyModel'
    import WhatsappContact from '@/components/socials/WhatsappContact';

    export default {
        name: "MainContent",
        components:{WhatsappContact},
        mixins: [mixin],
        props: {
            description: String,
            others: Array,
            area: Number,
            phoneNumber: String,
            information: null,
            features: null,
            form: null
        },
        data: function () {
            return {
                isModalVisible: false,
                target: 'features',
                pagination: {},
                dataLoaded: false
            }
        },
        async created(){
            await this.getKeyItems(
                    buildPayloadPagination({}, this.buildSearch(), this.target)
                )
                
                this.dataLoaded = true
        },
        methods: {
            show(item) {
                this.$emit('show',item);
            },
            buildSearch() {
                return this.search
                ? { query: this.search, fields: this.fieldsToSearch.join(',') }
                : {}
            },
            returnType(t){
                if(t=="m2")
                    if(this.$i18n.locale == 'en')
                        return 'Sq Ft'
                    
                
                return t
            },
            processEnergyType(e){
                let isento = {
                    pt: 'Isento',
                    en: 'Exempt'
                }
                let processo = {
                    pt: "Em Processo",
                    en: "In Process"
                }

                if(e == 'Isento' || e == 'Exempt')
                    return this.$processLanguage(isento)

                if(e == 'Em Processo' || e == 'In Process')
                    return this.$processLanguage(processo)

                return e
            }
        },
        computed:{
            featuresList(){
                let retrievedData = this.$store.state.keysModule.key.features.data
                if(this.features == null)
                    return null
                let returnedArray = []
                this.features.features_id.forEach(function(key){
                    retrievedData.forEach(function(retriKey){
                        if(key == retriKey.id)
                            returnedArray.push(retriKey.name)
                    })
                })
                return returnedArray
            },
            computeFeatures(){
                return this.features
            },
            computeInformation(){
                if(this.information == null || this.information.length == 0 || this.information == undefined)
                    return null
                    
                return this.information
            }
        }
        
    }
</script>