<template>
  <div class="thumbnail-overlay">
    <div
      class="thumbnail-overlay-image"
      :style="`background-image: url(${imageUrl})`"
    ></div>
    <button class="video-button" aria-label="Reproduzir">
      <svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">
        <path
          class="video-play-button-bg"
          d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
          fill="#f00"
        ></path>
        <path d="M 45,24 27,14 27,34" fill="#fff"></path>
      </svg>
    </button>
  </div>
</template>
<style lang="scss" scoped>
.thumbnail-overlay{
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-transition: opacity .25s cubic-bezier(0,0,0.2,1);
  transition: opacity .25s cubic-bezier(0,0,0.2,1);
  background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;

  .thumbnail-overlay-image{
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    transform: scale(1.1);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
  }
  .video-button{
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0;
    color: inherit;
    text-align: inherit;
    font-size: 100%;
    font-family: inherit;
    cursor: default;
    line-height: inherit;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 68px;
    height: 48px;
    margin-left: -34px;
    margin-top: -24px;
    -webkit-transition: opacity .25s cubic-bezier(0,0,0.2,1);
    transition: opacity .25s cubic-bezier(0,0,0.2,1);
    z-index: 63;

    &:focus {
      outline: 0;
    }
    svg {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      .video-play-button-bg{
        -webkit-transition: fill .1s cubic-bezier(0.4,0,1,1),fill-opacity .1s cubic-bezier(0.4,0,1,1);
        transition: fill .1s cubic-bezier(0.4,0,1,1),fill-opacity .1s cubic-bezier(0.4,0,1,1);
        fill: #212121;
        fill-opacity: .8;
      }
    }
  }
  &:hover{
    .thumbnail-overlay-image{
      transform: scale(1.25);
    }
    .video-button .video-play-button-bg{
      -webkit-transition: fill .1s cubic-bezier(0,0,0.2,1),fill-opacity .1s cubic-bezier(0,0,0.2,1);
      transition: fill .1s cubic-bezier(0,0,0.2,1),fill-opacity .1s cubic-bezier(0,0,0.2,1);
      fill: #f00;
      fill-opacity: 1;
    }
  }
}
</style>
<script>
export default {
  name: 'VideoMask',
  props:['imageUrl']
}
</script>