<template>
    <div class="lg:px-24 md:px-8 px-3">
        <div :class="'grid-cols-' + numberCols" class="grid gap-2 lg:gap-4">

            <!-- Youtube video image poster. We need to pass the youtube link to video and call the first image -->
            <div class="md:h-20 lg:h-44 overflow-hidden rounded-lg lg:rounded-xl cursor-pointer" style="z-index: 9999;" v-if="hasVideo" @click="showVideo()">
                <!-- <video controls :poster="'https://img.youtube.com/vi/'+ youtubeVideoComputed + '/default.jpg'" 
                    class="hover:scale-125 scale-110 origin-center transition-all duration-500 ease-in-out transform cursor-pointer object-center w-full md:h-20 lg:h-44 object-cover">
                    
                    <source :src="'https://www.youtube.com/watch?v='+youtubeVideoComputed" type="video/mp4">
                    Your browser does not support the video tag.
                </video> -->
                <VideoMask :imageUrl="'https://i.ytimg.com/vi_webp/'+ youtubeVideoComputed + '/hqdefault.webp'"/>
            </div>
            <!-- Youtube video image poster end -->

            <div class="md:h-20 lg:h-44 overflow-hidden rounded-lg lg:rounded-xl" 
                v-for="item in imagesSliced" :key="item.id">
                
                <img @click="show(item)" 
                class="hover:scale-125 scale-110 origin-center transition-all duration-500 ease-in-out transform cursor-pointer object-center w-full md:h-20 lg:h-44 object-cover" 
                :src="$mediaFile('properties', item.property_id , item.file)"
                :lazy-src="$mediaFile('properties', item.property_id , item.file)">

                <!-- <WatermarkImage 
                  :imageSrc="$mediaFile('properties', item.property_id , item.file)" 
                  @click="show(item)"
                  v-if="false"
                  myclass="hover:scale-125 scale-110 origin-center transition-all duration-500 ease-in-out transform cursor-pointer object-center w-full md:h-20 lg:h-44 object-cover"
                /> -->
            </div>
            
        </div>
        <!-- button -->
        <div class="w-full flex justify-center items-center mt-6">
            <button @click="show()" class="hover:text-bronze mt-6 py-1 text-sm font-bold uppercase leading-none tracking-button inset-x-0 mx-auto">
                <span class="mr-2">{{ $t('buttons.viewAll') }}</span>
            </button>
        </div>
    </div>
</template>
<script>
import { getIdFromURL } from 'vue-youtube-embed'
import VideoMask from '@/components/media/VideoMask'
    export default {
        name: "GalleryRow",
        components: {
          VideoMask,
        },
        props: {
            numberImgs: Number,
            numberCols: Number,
            gallery: Array,
            youtubeVideo: {
                type: String,
                default: ''
            }
        },
        data: function () {
            return {
                imagesSliced: this.gallery.slice(0, this.numberImgs),
                isModalVisible: false,
            }
        },
        methods: {
            show(item) {
                this.$emit('show',item);
            },
            showVideo(){
                this.$emit('show-video')
            }
        },
        computed:{
            youtubeVideoComputed(){
                let videoId = getIdFromURL(this.youtubeVideo)
                return videoId
            },
            hasVideo(){
                if(this.youtubeVideo == '')
                    return false

                if(this.youtubeVideo == null)
                    return false

                return true
            }
        }
    }
</script>

